// TODO: Need to consider websockets for realtime updates here.

// Angular:
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// Libs
import * as _ from 'lodash';
const showdown  = require('showdown');
const converter = new showdown.Converter();

// Services:
import { AuthenticationService, ContactsService, LoaderService, ToastService } from '@app/_services';
import { ThreadsService } from '@app/_services/threads.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-vlasingleconversation',
  templateUrl: './vlasingleconversation.component.html',
  styleUrls: ['./vlasingleconversation.component.less']
})
export class VLASingleConversationComponent implements OnInit {

  threadId: string;
  thread: any = {
    passedToOnsiteTeam: false,
  }; // TODO: Type this.
  backTo: string;
  contactName: string;
  messages: [any]; // TODO: Type this.
  replyMessage: any; // TODO: Type this.
  sendingMessage = false;
  registeredUserData: any; //TODO: Type this.
  adjustedHeight = 'calc(100vh - 120px)';
  convertToHTML; // TODO: Type this.
  contact: any; // TODO: Type this.
  threadMessages: [any];

  constructor(
    private loaderService: LoaderService,
    private threadsService: ThreadsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private toastService: ToastService,
    private contactService: ContactsService,
    private sanitizer: DomSanitizer,
  ) {
    this.registeredUserData = this.authService.currentUserValue;
  }

  ngOnInit() { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.threadId = params['id'];
      this.backTo = params['backTo'];
      this.contactName = params['contactName'];
      this.loadSingleThread();
    });

    // Dynamically set style to give CS a good experience too :)
    if (this.registeredUserData.user.claims.includes('admin')) {
      this.adjustedHeight = 'calc(100vh - 192px)';
    }
  }

  goBack() {
    this.router.navigate([this.backTo], { skipLocationChange: false });
  }

  async loadSingleThread(): Promise<void> {
    this.loaderService.triggerLoader();
    const data = await this.threadsService.getThread(this.threadId).toPromise();
    const messages = await this.threadsService.getThreadMessages(this.threadId).toPromise();
    this.threadMessages = messages.result;
    this.threadMessages = this.threadMessages.sort((a, b) => {
      return a.created - b.created;
    });
    console.log('threadMessages: ', this.threadMessages);
    // TODO: Add check that we got the data back we were expecting.
    const contactData = await this.contactService.getContact(data.result.nurtureBossThread.contactId).toPromise();
    this.contact = contactData.result;
    if (this.messages) {
      this.messages = data.result.messages;
      for (const message of this.messages) {
        message.HTMLConversion = '<div class="inner-text-wrap">' + converter.makeHtml(message.content[0].text.value) + '</div>';
      }
    }
    if (data.result.nurtureBossThread.transcript) {
      data.result.nurtureBossThread.transcript = data.result.nurtureBossThread.transcript.replace('/AI\:/g', '<br/>AI:')
      data.result.nurtureBossThread.transcript = data.result.nurtureBossThread.transcript.replace('/User\:/g', '<br/>User:')
      data.result.nurtureBossThread.transcript = this.sanitizer.bypassSecurityTrustHtml(data.result.nurtureBossThread.transcript);
      console.log(data.result.nurtureBossThread.transcript);
    }
    this.thread = data.result.nurtureBossThread;
    this.scrollView();
    this.loaderService.stopLoader();
  }

  toDate(secs: number) {
    return new Date(secs * 1000);
  }

  async disableAIForContact() {
    try {
      this.loaderService.triggerLoader();
      const updatedThread = await this.threadsService.updateThread(this.threadId, { passedToOnsiteTeam: true }).toPromise();
      this.thread = updatedThread.result;
      this.loaderService.stopLoader();
      this.toastService.showSuccess('AI disabled for contact');
    } catch(e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not disable AI. Please contact customersuccess@nurtureboss.io');
    }
  }
  async enableAIForContact() {
    try {
      this.loaderService.triggerLoader();
      const updatedThread = await this.threadsService.updateThread(this.threadId, { passedToOnsiteTeam: false }).toPromise();
      this.thread = updatedThread.result;
      this.loaderService.stopLoader();
      this.toastService.showSuccess('AI enabled for contact');
    } catch(e) {
      this.loaderService.stopLoader();
      this.toastService.showError('Could not enable AI. Please contact customersuccess@nurtureboss.io');
    }
  }

  async sendMessage(_e) {
    if (this.replyMessage && this.threadId) {
      try {
        this.sendingMessage = true;
        await this.threadsService.addMessageToThread(
          this.threadId,
          {
            threadIdType: 'OpenAI',
            role: 'assistant',
            threadId: this.thread.threadId,
            message: this.replyMessage,
            contactId: this.thread.contactId,
            clientEmailAddress: this.contact.emailAddress,
            sendEmail: true,
          }
        ).toPromise();
        this.messages.push({
          created_at: new Date().getTime(),
          role: 'assistant',
          HTMLConversion: this.replyMessage,
          content: [
            {
              type: 'text',
              text: {
                value: this.replyMessage,
              }
            }
          ]
        });
        this.scrollView();
        this.replyMessage = '';
        this.sendingMessage = false;
      } catch (e) {
        this.sendingMessage = false;
        this.toastService.showError('There was an error sending your message. Please try again later.');
      }
    } else {
      this.sendingMessage = false;
      this.toastService.showError('Missing required information to send message');
    }
  }

  scrollView () {
    setTimeout(function() {
      var elem = document.getElementById('chat-window');
      if (elem) {
        elem.scrollTop = elem.scrollHeight;
      }
    });
  }
}
